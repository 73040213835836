import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaUsers, FaRegSun, FaUser } from "react-icons/fa";
import { MdOutlineCategory } from "react-icons/md";
import { PiChatsBold } from "react-icons/pi";
import { ImCalculator } from "react-icons/im";
import { FaCalculator } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiUserSettingsLine } from "react-icons/ri";
import { FaUserGear } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Layout, Menu, theme } from "antd";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./MainLayout.scss";
import Login from "../pages/Login";
const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  // const notify = () =>{ }
  function logout() {
    localStorage.removeItem("adminToken");
    // notify()
    toast.warn("Logged Out!", {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate("/Login");
    }, 1000);
  }

  const handleMailClick = () => {
    // Redirect to the specified URL

    const gmailURL = "https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox";

    // Open the URL in a new tab
    window.open(gmailURL, "_blank");
  };
  //   window.location.href =
  //     "https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox";
  // };

  const token = localStorage.getItem("adminToken");
  return (
    <>
      {token ? (
        <Layout /* onContextMenu={(e) => e.preventDefault()} */>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className="sidebar"
          >
            <div className="logo">
              <h2 className="text-white fs-5 text-center py-3 mb-0">
                <span className="sm-logo">APT</span>
                <span className="lg-logo">Automated Pricing Tool</span>
              </h2>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[""]}
              onClick={({ key }) => {
                if (key === "signout") {
                } else {
                  navigate(key);
                }
              }}
              items={[
                {
                  key: "/",
                  icon: <AiOutlineDashboard className="fs-4" />,
                  label: "Dashboard",
                },
                {
                  key: "/customers",
                  icon: <FaUsers className="fs-4" />,
                  label: "Customers",
                },

                {
                  key: "proposals",
                  icon: <IoDocumentsSharp className="fs-4" />,
                  label: "Proposal",
                  children: [
                    {
                      key: "category",
                      icon: <BiCategoryAlt className="fs-4" />,
                      label: "Proposal Category ",
                    },
                    {
                      key: "addProposal",
                      icon: <IoDocumentText className="fs-4" />,
                      label: "Add Proposal",
                    },
                    {
                      key: "proposals",
                      icon: <IoDocumentText className="fs-4" />,
                      label: "Proposal List",
                    },
                  ],
                },
                {
                  key: "calculator_",
                  icon: <ImCalculator className="fs-4" />,
                  label: "Calculator",
                  children: [
                    {
                      key: "calculator",
                      icon: <FaCalculator className="fs-4" />,
                      label: "Calculator's List",
                    },
                  ],
                },
                {
                  key: "content_",
                  icon: <FaHome className="fs-4" />,
                  label: "Home Page ",
                  children: [
                    {
                      key: "addContent",
                      icon: <LuLayoutDashboard className="fs-4" />,
                      label: "Add Content",
                    },
                    {
                      key: "/content",
                      icon: <LuLayoutDashboard className="fs-4" />,
                      label: "Content List",
                    },
                    {
                      key: "/bannerContent",
                      icon: <MdOutlineCategory className="fs-4" />,
                      label: "Banner Content",
                    },
                    {
                      key: "/socialLogins",
                      icon: <PiChatsBold className="fs-4" />,
                      label: "Social logins",
                    },
                  ],
                },
                {
                  key: "/adminList",
                  icon: <FaUserGear className="fs-4" />,
                  label: "Admin Management",
                },
              ]}
            />
          </Sider>
          <Layout className="site-layout">
            <Header
              className="d-flex justify-content-between ps-1 pe-5"
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
              <div className="d-flex gap-4 align-items-center">
                {/* <div className="position-relative">
              <IoIosNotifications className="fs-4" />
              <span className="badge bg-warning rounded-circle p-1 position-absolute">
                3
              </span>
            </div> */}

                <div className="position-relative">
                  <Link to="https://automatedpricingtool.io/" target="_blank">
                    Visit Website
                  </Link>
                </div>

                <div className="d-flex gap-4 align-items-center">
                  <div className="position-relative clicked">
                    <FiMail
                      className="fs-3"
                      onClick={handleMailClick}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    />
                    {/* <span className="badge bg-warning rounded-circle p-1 position-absolute">
                    3
                  </span> */}
                  </div>
                </div>

                <div className="d-flex gap-3 align-items-center dropdown">
                  <div
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaUser className="fs-5" />
                  </div>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link
                        className="dropdown-item py-1 mb-1"
                        style={{ height: "auto", lineHeight: "20px" }}
                        to="/viewAdmin"
                      >
                        View Profile
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="dropdown-item py-1 mb-1"
                        style={{ height: "auto", lineHeight: "20px" }}
                        to="/adminList"
                      >
                        Add Another Admin Account
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className="dropdown-item py-1 mb-1"
                        style={{ height: "auto", lineHeight: "20px" }}
                        to="/reset-password"
                      >
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item py-1 mb-1"
                        style={{ height: "auto", lineHeight: "20px" }}
                        onClick={() => logout()}
                      >
                        Signout
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </Header>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >
              <ToastContainer
                position="top-right"
                autoClose={250}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="light"
              />
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
};
export default MainLayout;
