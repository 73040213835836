import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import {
  GetProposalById,
  UpdateProposals,
  GetAllCategory,
} from "../../services/Api/Api";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CloseIcon from "@mui/icons-material/Close";

const EditProposal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [proposalData, setProposalData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [checkImage, setCheckImage] = React.useState(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [docs, setDoc] = useState({ preview: "", raw: "" });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigateToProposal = () => {
    navigate("/proposals");
  };

  //get proposal By ID
  useLayoutEffect(() => {
    GetProposalById(id)
      .then((res) => {
        setProposalData(res.data.data);
        setCheckImage(res.data.data.file_name);
        console.log("file==>", res.data.data);
        setSelectedCategoryList(res.data.data?.Categories);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  //get category name
  const getCategoryList = async () => {
    let res = await GetAllCategory();
    if (res?.status === 200) {
      setCategoryList(res?.data?.data);
    } else {
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const handleCategory = (e) => {
    e.preventDefault();
    let categoryTitle = "";
    for (let i in categoryList) {
      if (categoryList[i].id == e.target.value) {
        categoryTitle = categoryList[i].title;
      }
    }

    let category = [...selectedCategoryList];

    for (let i in category) {
      if (category[i].id == e.target.value) {
        categoryTitle = "";
      }
    }

    const temp = {
      id: e.target.value,
      title: categoryTitle,
    };

    if (categoryTitle !== "") {
      category.push(temp);
      setSelectedCategoryList(category);
    }

  };

  const handleRemoveCategory = (e, item) => {
    e.preventDefault();
    let category = [...selectedCategoryList];
    category = category.filter((e) => e.title !== item.title);
    setSelectedCategoryList(category);
  };

  const handleDocChange = (e) => {
    const allowedExtensions = ["doc", "docx", "pdf", "xls", "xlsx"];
  
    if (e.target.files[0]) {
      setLoading(true);
      console.log("newfile==>", e.target.files);
      const fileExtension = e.target.files[0].name
        .split(".")
        .pop()
        .toLowerCase();
  
      setFile(e.target.files[0]);
      if (allowedExtensions.includes(fileExtension)) {
        console.log("e.target.files[0]", e.target.files[0], "DONE");
        setDoc({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        setProposalData({ ...proposalData, docs: e.target.files[0] });
  
        // Reset loading to false once the preview is available
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Only .doc, .docx, .pdf, csv, .xls, and .xlsx files are allowed.");
      }
    }
  };
  

  const fileInputRef = useRef(null);

  // Function to remove the selected document
  const handleRemoveDocument = () => {
    fileInputRef.current.value = "";
    setDoc({
      preview: "",
      raw: "",
    });
    setFile(null);
    URL.revokeObjectURL(docs);
  };

  const onChange = (e) => {
    setProposalData({ ...proposalData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("proposal_id", id);
    formData.append("title", proposalData?.title ? proposalData?.title : "");
    formData.append(
      "description",
      proposalData?.description ? proposalData?.description : ""
    );
    // if (proposalData.image) {
    //   formData.append("images", proposalData.image);
    // }
    if (proposalData.docs) {
      formData.append("docs", proposalData.docs);
    }
    // Check if categories are selected; if not, use the existing categories
    // if (selectedCategoryList.length > 0) {
    //   const catIdList = [];
    //   for (let i in selectedCategoryList) {
    //     catIdList.push(selectedCategoryList[i].id);
    //   }
    //   formData.append("categories", catIdList.join(","));
    // }

    formData.append("categories", selectedCategoryList.map(category => category.id).join(","));
    console.log("FormData:", formData);
    UpdateProposals(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Proposal edited successfully!");
        }
        navigate("/proposals");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProposalData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          Edit Proposal
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Title of Proposal:</Form.Label>
                <Form.Control
                  name="title"
                  defaultValue={proposalData?.title}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Categories:</Form.Label>
                <Form.Control
                  disabled
                  name="type"
                  type="text"
                  defaultValue={proposalData?.Categories?.map(
                    (category) => category.title
                  ).join(", ")}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Select Category:</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  // value={userType}
                  onChange={(e) => handleCategory(e)}
                  className="new_form_control"
                >
                  <option>Choose Category</option>
                  {categoryList.map((item, index) => {
                    return <option value={item.id}>{item?.title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              {selectedCategoryList.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      handleRemoveCategory(e, item);
                    }}
                  >
                    <text style={{ fontSize: 16 }}>{item.title}</text>
                    <CloseIcon fontSize="small" color="warning" />
                  </div>
                );
              })}
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Proposal Type</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.proposal_type}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row>
              {proposalData && proposalData.proposal_type === "DOWNLOADABLE" ? (
                <Form.Group className="mb-3">
                  <Form.Label>Document:</Form.Label>
                  <Form.Control
                    type="file"
                    name="docs"
                    accept=".docx, .doc, .pdf, .xls, .xlsx, .csv"
                    ref={fileInputRef}
                    onChange={(e) => handleDocChange(e)}
                  />
                  {loading ? (
                    <div>Loading...</div>
                  ) : proposalData ? (
                    <>
                      {docs?.preview === "" ? (
                        <iframe
                          title="document"
                          src={`https://docs.google.com/viewer?url=https://node.automatedpricingtool.io:5000/Docs/${proposalData?.file_name}&embedded=true`}
                          width="500"
                          height="300"
                          style={{ marginTop: "20px", marginLeft: "20px" }}
                        ></iframe>
                      ) : (
                        // <div>{docs?.raw?.name}</div>
                        <div
                          style={{
                            position: "relative",
                            backgroundColor: "#EBECF2",
                            borderRadius: "8px",
                            width: "210px",
                            padding: "8px",
                            alignItems: "center",
                            height: "40px",
                            marginTop: "30px",
                          }}
                        >
                          <p>
                            {docs?.raw?.name?.length && (docs?.raw?.name?.length > 20
                              ? docs?.raw?.name?.slice(0, 17) + "..."
                              : docs?.raw?.name)}
                          </p>
                          <CloseIcon
                            fontSize="small"
                            color="warning"
                            onClick={(e) => {
                              handleRemoveDocument(e);
                            }}
                            style={{
                              position: "absolute",
                              right: "-10px",
                              top: "-8px",
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <span>No Document Available</span>
                  )}
                </Form.Group>
              ) : proposalData && proposalData.proposal_type === "EDITABLE" ? (
                <>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Image:</Form.Label>

                    <Form.Control
                      type="file"
                      name="image"
                      onChange={(e) => handleImageChange(e)}
                    />
                    {proposalData ? (
                      <>
                        {image.preview === "" ? (
                          <img
                            src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
                            alt="Proposal Banner "
                            style={{
                              height: "100px",
                              width: "100px",
                              marginTop: "20px",
                              marginLeft: "20px",
                            }}
                          />
                        ) : (
                          <img
                            src={image.preview}
                            alt="Preview"
                            style={{
                              height: "100px",
                              width: "100px",
                              marginTop: "20px",
                              marginLeft: "20px",
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <span>No Image Available</span>
                    )}
                  </Form.Group> */}
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Proposal:</Form.Label>

                    <CKEditor
                      editor={ClassicEditor}
                      onChange={handleEditorChange}
                      data={proposalData?.description}
                      config={{
                        height: "1000px",
                      }}
                    />
                  </Form.Group> */}
                </>
              ) : (
                <span>No File Available</span>
              )}
            </Row>

            <div className="button">
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                onClick={handleSubmit}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Save
              </Button>
              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToProposal();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default EditProposal;
