import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { GetCalculatorById } from "../../../services/Api/Api";
import { BASE_URL_IMAGE } from "../../../services/Host";
import { Card } from "primereact/card";

const ViewCalculator = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [proposalData, setProposalData] = useState([]);

  const navigateToCalculator = () => {
    navigate("/calculator");
  };

  //get role By ID
  useLayoutEffect(() => {
    GetCalculatorById(id)
      .then((res) => {
        setProposalData(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          View Calculator
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Name</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.calculator_name}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>
                <br />
                {proposalData ? (
                  <img
                    src={`${BASE_URL_IMAGE}${proposalData?.file_name}`}
                    alt="proposal Icon"
                    className="category-icon-preview"
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <span>No Image Available</span>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.description}
                  as="textarea"
                  disabled
                  // rows={6}
                />
              </Form.Group>
            </Row>

            {/* <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Use:</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.calculator_use}
                  as="textarea"
                  disabled
                  rows={3}
                />
              </Form.Group>
            </Row> */}

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Use, Content & Formula:</Form.Label>
                <div
                  style={{
                    backgroundColor: "#EBECF2",
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid #ced4da ",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: proposalData?.profit_margin_formula,
                  }}
                />
                {/* <Form.Control
                  name="name"
                  as="textarea"
                  disabled
                  rows={6}
                  // defaultValue={proposalData?.profit_margin_formula}
                  // dangerouslySetInnerHTML={{
                  //   __html: proposalData?.profit_margin_formula,
                  // }}
                /> */}
              </Form.Group>
            </Row>

            <div>
              <Button
                // icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToCalculator();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Return to Calculator
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default ViewCalculator;
