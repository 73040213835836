import axios from "axios";
import { BASE_URL } from "../Host";
import requestWithToken from "./Config";

//admin login
export const AdminLogin = async (data) => {
  let config = {
    email: data?.email,
    password: data?.password,
  };
  console.log("loginnnn==>", BASE_URL + "admin/login");
  return await axios.post(BASE_URL + "admin/login", config);
};

//get all user
export const GetUsers = async () => {
  return await requestWithToken(BASE_URL + "admin/getAllUsers", "GET");
};

export const GetUserById = async (id) => {
  return await axios.get(BASE_URL + "admin/getUserById?id=" + id);
};

//admin add user
export const AddUser = async ({ email, name, subscription_status }) => {
  const formData = new FormData();

  formData.append("email", email);
  formData.append("name", name);
  formData.append("subscription_status", subscription_status);

  return await axios.post(BASE_URL + "admin/createUser", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//delete user
export const DeleteUser = async (user_id, adminToken) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  return axios.delete(BASE_URL + "admin/deleteUser", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

export const ChangeAdminPassword = async (data) => {
  return axios.post(BASE_URL + "admin/change-password", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": `${localStorage.getItem("adminToken")}`,
    },
  });
};

export const GetAdmins = async () => {
  return await requestWithToken(BASE_URL + "admin/getAllAdmin", "GET");
};

export const GetAdminById = async (id) => {
  return await axios.get(BASE_URL + "admin/getAdminById?id=" + id);
};

export const UpdateAdmin = async (formData) => {
  return await axios.put(BASE_URL + "admin/updateAdmin", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteAdmin = async (admin_id, adminToken) => {
  const formData = new FormData();
  formData.append("admin_id", admin_id);
  return axios.delete(BASE_URL + "admin/deleteAdmin", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

export const GetAdminProfile = async () => {
  const token = await localStorage.getItem("adminToken");
  console.log("token-=========>", token);
  return await axios.get(BASE_URL + "admin/getAdminProfile", {
    headers: {
      "x-access-token": `${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateAdmin = async (formData) => {
  return await axios.post(BASE_URL + "admin/register", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const ChangePaymentStatus = async ({ user_id, payment_status }) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("payment_status", payment_status);

  return await axios.post(BASE_URL + "admin/updatepaymentStatus", formData);
};

export const GetAllRoles = async () => {
  const res = await axios.get(BASE_URL + "role/all");

  return res;
};

export const GetRoleById = async (id) => {
  return await axios.get(BASE_URL + "role/getRolebyId?id=" + id);
};

export const CreateRole = async (formData) => {
  return await axios.post(BASE_URL + "role/create", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdateRoles = async (formData) => {
  return await axios.put(BASE_URL + "role/editRole", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteRole = async (role_id, adminToken) => {
  const formData = new FormData();
  formData.append("role_id", role_id);
  return axios.delete(BASE_URL + "role/deleteRole", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

export const GetAllProposal = async () => {
  const res = await axios.get(BASE_URL + "proposal/all");

  return res;
};

export const GetProposalById = async (id) => {
  return await axios.get(BASE_URL + "proposal/getProposalById?id=" + id);
};

export const CreateProposal = async (formData) => {
  return await axios.post(BASE_URL + "proposal/createProposal", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdateProposals = async (formData) => {
  return await axios.put(BASE_URL + "proposal/updateProposal", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteProposal = async (proposal_id, adminToken) => {
  const formData = new FormData();
  formData.append("proposal_id", proposal_id);
  return axios.delete(BASE_URL + "proposal/deleteProposal", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

export const GetHomePageContent = async () => {
  const res = await axios.get(BASE_URL + "content/getAllContent");

  return res;
};

export const GetHomePageContentById = async (id) => {
  return await axios.get(BASE_URL + "content/findContentById?id=" + id);
};

export const CreateHomePageContent = async (formData) => {
  return await axios.post(BASE_URL + "content/createContent", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdateHomePageContent = async (formData) => {
  return await axios.put(BASE_URL + "content/updateContent", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteHomePageContent = async (content_id, adminToken) => {
  const formData = new FormData();
  formData.append("content_id", content_id);
  return axios.delete(BASE_URL + "content/deleteContent", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

// **************************************CALCULATOR APIS*************************************************
export const CreateCalculator = async (formData) => {
  return await axios.post(BASE_URL + "calculator/createCalculator", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GetAllCalculator = async () => {
  const res = await axios.get(BASE_URL + "calculator/all");

  return res;
};

export const GetCalculatorById = async (id) => {
  return await axios.get(BASE_URL + "calculator/getCalculatorById?id=" + id);
};

export const UpdateCalculator = async (formData) => {
  return await axios.put(BASE_URL + "calculator/updateCalculator", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteCalculator = async (calculator_id, adminToken) => {
  const formData = new FormData();
  formData.append("calculator_id", calculator_id);
  return axios.delete(BASE_URL + "calculator/deleteCalculator", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

// ***************************************DASHBOARD APIS*********************************

export const GetUserCount = async () => {
  return await requestWithToken(BASE_URL + "admin/getCount", "GET");
};

export const GetProposalCount = async () => {
  const res = await axios.get(BASE_URL + "admin/getProposalCount");
  return res;
};

export const GetUserCountByMonth = async () => {
  const res = await axios.get(BASE_URL + "admin/getUserCountByMonth");
  return res;
};

export const GetMostViewdProposal = async () => {
  const res = await axios.get(BASE_URL + "admin/getMostViewedProposal");
  return res;
};

export const GetMostViewedCalculator = async () => {
  const res = await axios.get(BASE_URL + "admin/getMostViewedCalculator");
  return res;
};

// export const GetLoginLogs = async () => {
//   const res = await axios.get(BASE_URL + "admin/getLoginLogs");
//   return res;
// };

export const GetLoginLogs = async ({ month, year }) => {
  const formData = new FormData();
  formData.append("month", month);
  formData.append("year", year);
  return await axios.post(BASE_URL + "admin/getLoginLogsOfUser", formData);
};

//get all categroy
export const GetAllCategory = async () => {
  const res = await axios.get(BASE_URL + "category/all");
  return res;
};

//get category by id
export const GetCategoryById = async (id) => {
  return await axios.get(BASE_URL + "category/getCategoryById?id=" + id);
};

export const UpdateCategory = async (formData) => {
  return await axios.put(BASE_URL + "category/updateCategory", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateCategory = async (formData) => {
  return await axios.post(BASE_URL + "category/createCategory", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteCategory = async (category_id, adminToken) => {
  const formData = new FormData();
  formData.append("category_id", category_id);
  return axios.delete(BASE_URL + "category/deleteCategory", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

//*************************************************Banner content api******************************************************** */

export const GetAllBannerContent = async () => {
  const res = await axios.get(BASE_URL + "content/getAllBannerContent");
  return res;
};

export const GetBannerContentById = async (id) => {
  return await axios.get(BASE_URL + "content/findBannerContentById?id=" + id);
};

export const CreateBannerContent = async (formData) => {
  return await axios.post(BASE_URL + "content/createBannerContent", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdateBannerContent = async (formData) => {
  return await axios.put(BASE_URL + "content/updateBannerContent", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const DeleteBannerContent = async (content_id, adminToken) => {
  const formData = new FormData();
  formData.append("content_id", content_id);
  return axios.delete(BASE_URL + "content/deleteBannerContent", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};

// ********************************Social APIs*********************************************************

//get all social login
export const GetAllSocialLogin = async () => {
  const res = await axios.get(BASE_URL + "content/getAllSocialLogin");
  return res;
};

//get social login by id
export const GetSocialLoginById = async (id) => {
  return await axios.get(BASE_URL + "content/findSocialLoginById?id=" + id);
};

//create social login
export const CreateSocialLogin = async (formData) => {
  return await axios.post(BASE_URL + "content/createSocialLogin", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//update social
export const UpdateSocialLogin = async (formData) => {
  return await axios.put(BASE_URL + "content/updateSocialLogin", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//delete social
export const DeleteSocialLogin = async (content_id, adminToken) => {
  const formData = new FormData();
  formData.append("content_id", content_id);
  return axios.delete(BASE_URL + "content/deleteSocialLogin", {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": adminToken,
    },
  });
};
