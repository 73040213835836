import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Form from "react-bootstrap/Form";
import { CreateCalculator } from "../../../services/Api/Api";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import imageCompression from "browser-image-compression";

const AddCalculator = () => {
  const navigate = useNavigate();
  const [calculatorName, setCalculatorName] = useState("");
  const [description, setDescription] = useState("");
  const [calculatorUse, setCalculatorUse] = useState("");
  const [profitMrginFormula, setProfitMrginFormula] = useState("");
  const [images, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [disable, setDisable] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]; // Add more allowed types if needed

      if (!allowedTypes.includes(file.type)) {
        console.error("Error: Invalid file type. Images (JPEG, JPG,PNG) only!");
        return;
      }

      if (file.size <= 1024 * 1024) {
        // If image size is less than or equal to 1 MB, no need to compress
        setImages(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Compress the image to 25% if its size is more than 1 MB
        try {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
          });

          // Check if compression actually reduced the size
          if (compressedFile.size < file.size) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
              setImages(new Blob([compressedFile], { type: "image/jpeg" }));
            };
            reader.readAsDataURL(compressedFile);
          } else {
            // If compression did not reduce the size, use the original image
            setImages(file);
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
          }
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault();
    setImages(null);
    setImagePreview(null);

    // Clear the file input value
    const fileInput = document.getElementById("imageInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProfitMrginFormula(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!calculatorName) {
      setDisable(false);
      toast.error("Please enter calculator Name");
      return;
    }
    // if (!calculatorUse) {
    //   setDisable(false);
    //   toast.error("Please enter calculator Use");
    //   return;
    // }
    // if (!profitMrginFormula) {
    //   setDisable(false);
    //   toast.error("Please enter Formula");
    //   return;
    // }

    try {
      const formData = new FormData();
      formData.append("calculator_name", calculatorName);
      formData.append("calculator_use", calculatorUse);
      formData.append("profit_margin_formula", profitMrginFormula);
      formData.append("description", description);
      if (images) {
        formData.append("images", images, "compressed_image.jpg");
      }

      const response = await CreateCalculator(formData);
      console.log("response===>", response);

      if (response.status === 200) {
        toast.success("Calculator added successfully");
        setTimeout(() => {
          // Refresh the page
          window.location.reload();
        }, 1000);
      }
      setDisable(false);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Calculator with this title already exist", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error.response.status === 401) {
        toast.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const navigateToProposal = () => {
    navigate("/calculator");
  };

  return (
    <div>
      <h3 style={{ marginBottom: "30px" }}>Create a New Calculator</h3>

      <Card>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label> Calculator Name</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Calculator Name"
                value={calculatorName}
                onChange={(e) => setCalculatorName(e.target.value)}
                className="new_form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Image:</Form.Label>
              <Form.Control
                type="file"
                required
                accept="images/*"
                id="imageInput"
                onChange={handleImageChange}
              />
            </Form.Group>
            {imagePreview && (
              <div
                style={{ position: "relative" }}
                onClick={(e) => {
                  handleRemoveImage(e);
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "10px",
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  color="warning"
                  style={{
                    position: "absolute",
                    left: "99px",
                  }}
                />
              </div>
            )}

            <Form.Group className="mb-3">
              <Form.Label> Calculator Description</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Calculator Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="new_form_control"
              />
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label> Calculator Use</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Calculator Use"
                value={calculatorUse}
                rows={6}
                onChange={(e) => setCalculatorUse(e.target.value)}
                className="new_form_control"
              />
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>Calculator Use, Content & Formula:</Form.Label>

              <CKEditor
                editor={ClassicEditor}
                onChange={handleEditorChange}
                config={{
                  height: "1000px",
                }}
              />
            </Form.Group>

            <div>
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                disabled={disable}
                onClick={handleSubmit}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                  // width:"10px"
                }}
              >
                {disable ? "Saving...." : "Save"}
              </Button>

              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToProposal();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddCalculator;
