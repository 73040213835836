
let HOST = 'http://localhost:3000/api/v1/';
// export const BASE_URL = "http://localhost:3000/api/v1/";
export const BASE_URL = "https://node.automatedpricingtool.io:5000/api/v1/";
export const BASE_URL_IMAGE =" https://node.automatedpricingtool.io:5000/images/"
export const BASE_URL_DOCS =" https://node.automatedpricingtool.io:5000/docs/"

export default HOST;


