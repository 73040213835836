import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Form from "react-bootstrap/Form";
import { CreateProposal, GetAllCategory } from "../../services/Api/Api";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import imageCompression from "browser-image-compression";

const AddProposal = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [proposalType, setProposLtype] = useState("");
  const [images, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [disable, setDisable] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const fileInputRef = useRef(null);

  // Function to handle document change
  const handleDocumentChange = (e) => {
    const selectedDocument = e.target.files[0];
    setDocument(selectedDocument);
    const previewURL = URL.createObjectURL(selectedDocument);
    setDocumentPreview(previewURL);
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Function to remove the selected document
  const handleRemoveDocument = () => {
    fileInputRef.current.value = "";
    setDocument(null);
    URL.revokeObjectURL(documentPreview);
    setDocumentPreview(null);
  };

  //get category name
  const getCategoryList = async () => {
    // Write your code here
    let res = await GetAllCategory();

    if (res?.status === 200) {
      setCategoryList(res?.data?.data);
    } else {
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const handleCategory = (e) => {
    let categoryTitle = "";
    for (let i in categoryList) {
      if (categoryList[i].id == e.target.value) {
        categoryTitle = categoryList[i].title;
      }
    }
    e.preventDefault();
    let category = [...selectedCategoryList];
    const temp = {
      id: e.target.value,
      title: categoryTitle,
    };
    category.push(temp);

    setSelectedCategoryList(category);
  };

  const handleRemoveCategory = (e, item) => {
    e.preventDefault();
    let category = [...selectedCategoryList];
    category = category.filter((e) => e !== item);
    setSelectedCategoryList(category);
  };

  // const handleImageChange = async (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]; // Add more allowed types if needed

  //     if (!allowedTypes.includes(file.type)) {
  //       console.error("Error: Invalid file type. Images (JPEG, JPG) only!");
  //       return;
  //     }

  //     if (file.size <= 1024 * 1024) {
  //       // If image size is less than or equal to 1 MB, no need to compress
  //       setImages(file);
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setImagePreview(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       // Compress the image to 25% if its size is more than 1 MB
  //       try {
  //         const compressedFile = await imageCompression(file, {
  //           maxSizeMB: 1,
  //           maxWidthOrHeight: 1920,
  //         });

  //         // Check if compression actually reduced the size
  //         if (compressedFile.size < file.size) {
  //           const reader = new FileReader();
  //           reader.onloadend = () => {
  //             setImagePreview(reader.result);
  //             setImages(new Blob([compressedFile], { type: "image/jpeg" }));
  //           };
  //           reader.readAsDataURL(compressedFile);
  //         } else {
  //           // If compression did not reduce the size, use the original image
  //           setImages(file);
  //           const reader = new FileReader();
  //           reader.onloadend = () => {
  //             setImagePreview(reader.result);
  //           };
  //           reader.readAsDataURL(file);
  //         }
  //       } catch (error) {
  //         console.error("Error compressing image:", error);
  //       }
  //     }
  //   }
  // };

  // const handleRemoveImage = (e) => {
  //   e.preventDefault();
  //   setImages(null);
  //   setImagePreview(null);

  //   // Clear the file input value
  //   const fileInput = document.getElementById("imageInput");
  //   if (fileInput) {
  //     fileInput.value = null;
  //   }
  // };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!title) {
      setDisable(false);
      toast.error("Please enter heading");
      return;
    }
    if (!proposalType) {
      setDisable(false);
      toast.error("Please enter Proposal Type");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("proposal_type", proposalType);
      console.log("type", proposalType);
      // if (images) {
      //   formData.append("images", images, "compressed_image.jpg");
      // }
      if (document) {
        formData.append("docs", document);
      }
      if (selectedCategoryList.length > 0) {
        const catIdList = [];
        for (let i in selectedCategoryList) {
          catIdList.push(selectedCategoryList[i].id);
        }
        formData.append("categories", catIdList.join(","));
      }
      console.log("response11===>", formData);

      const response = await CreateProposal(formData);
      console.log("response===>", response);

      if (response.status === 200) {
        setDisable(false);
        toast.success("Proposal added successfully");
        setTimeout(() => {
          // Refresh the page
          window.location.reload();
        }, 1000);
      }
      setDisable(false);
    } catch (error) {
      setDisable(false);
      if (error.response.status === 401) {
        toast.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const navigateToProposal = () => {
    navigate("/proposals");
  };

  const renderImageFields = () => {
    if (proposalType === "EDITABLE") {
      console.log("tye=====>", proposalType);
      return (
        <>
          <Form.Group className="mb-3">
            <Form.Label style={{ marginTop: "20px" }}> Title:</Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="new_form_control"
            />
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Label>Image:</Form.Label>
            <Form.Control
              type="file"
              required
              accept="images/*"
              id="imageInput"
              onChange={handleImageChange}
            />
          </Form.Group>

          {imagePreview && (
            <div
              style={{ position: "relative" }}
              onClick={(e) => {
                handleRemoveImage(e);
              }}
            >
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "10px",
                }}
              />
              <CloseIcon
                fontSize="small"
                color="warning"
                style={{
                  position: "absolute",
                  left: "99px",
                }}
              />
            </div>
          )} */}

          {/* <Form.Group className="mb-3">
            <Form.Label> Proposal</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              onChange={handleEditorChange}
              config={{
                height: "1000px",
              }}
            />
          </Form.Group> */}
        </>
      );
    } else if (proposalType === "DOWNLOADABLE") {
      return (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="new_form_control"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Document:</Form.Label>
            <Form.Control
              type="file"
              required
              accept=".docx, .doc, .pdf, .xls, .xlsx, .csv"
              id="documentInput"
              ref={fileInputRef}
              onChange={handleDocumentChange}
            />
          </Form.Group>
          {document && (
            <div
              style={{
                position: "relative",
                backgroundColor: "#EBECF2",
                borderRadius: "8px",
                width: "210px",
                padding: "8px",
                alignItems: "center",
                height: "40px",
                marginTop: "30px",
              }}
            >
              <p>
                {document.name?.length > 20
                  ? document.name.slice(0, 17) + "..."
                  : document.name}
              </p>
              <CloseIcon
                fontSize="small"
                color="warning"
                onClick={(e) => {
                  handleRemoveDocument(e);
                }}
                style={{
                  position: "absolute",
                  right: "-10px",
                  top: "-8px",
                }}
              />
            </div>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <h3 style={{ marginBottom: "30px" }}>Create a New Proposal</h3>

      <Card>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label style={{ marginBottom: "10px" }}>
                Proposal Type:
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="new_form_control"
                value={proposalType}
                onChange={(e) => {
                  setProposLtype(e.target.value);
                }}
              >
                <option>Select Type:</option>
                <option value="EDITABLE">EDITABLE</option>
                <option value="DOWNLOADABLE">DOWNLOADABLE</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Category:</Form.Label>

              <Form.Select
                aria-label="Default select example"
                // value={userType}
                onChange={(e) => handleCategory(e)}
                className="new_form_control"
              >
                <option>Choose Category</option>
                {categoryList.map((item, index) => {
                  return <option value={item.id}>{item?.title}</option>;
                })}
              </Form.Select>
            </Form.Group>
            {selectedCategoryList.map((item, index) => {
              return (
                <div
                  onClick={(e) => {
                    handleRemoveCategory(e, item);
                  }}
                >
                  <text style={{ fontSize: 16 }}>{item.title}</text>
                  <CloseIcon fontSize="small" color="warning" />
                </div>
              );
            })}

            {renderImageFields()}

            <div style={{ marginTop: "60px" }}>
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                disabled={disable}
                onClick={handleSubmit}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                  // width:"10px"
                }}
              >
                {disable ? "Saving...." : "Save"}
              </Button>

              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToProposal();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddProposal;
