import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import {
  GetHomePageContentById,
  UpdateHomePageContent,
} from "../../services/Api/Api";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BASE_URL_IMAGE } from "../../services/Host";

const EditContent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [proposalData, setProposalData] = useState([]);
  const [checkImage, setCheckImage] = React.useState(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [selectedValue, setSelectedValue] = useState("");
  const [orderNumber, setOrderNumber] = useState("");

  const navigateToContent = () => {
    navigate("/content");
  };

  const handleImageChange = (e) => {
    console.log("imageessss===>", e.target.value);
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
    setProposalData({ ...proposalData, image: e.target.files[0] });
  };

  //get proposal By ID
  useLayoutEffect(() => {
    GetHomePageContentById(id)
      .then((res) => {
        setProposalData(res.data.data);
        console.log("contentdata===>", res.data.data);
        setOrderNumber(res.data.data);
        // Set the default selected value to the fetched order number
        setSelectedValue(res.data.data.order_number);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  const onChange = (e) => {
    setProposalData({ ...proposalData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content_id", id);
    // formData.append(
    //   "order_number",
    //   proposalData?.order_number ? proposalData?.order_number : ""
    // );
    formData.append("order_number", selectedValue ? selectedValue : "");
    formData.append("title", proposalData?.title ? proposalData?.title : "");
    formData.append(
      "description",
      proposalData?.description ? proposalData?.description : ""
    );
    formData.append(
      "redirection_url",
      proposalData?.redirection_url ? proposalData?.redirection_url : ""
    );
    if (proposalData.image) {
      formData.append("images", proposalData.image);
    }

    UpdateHomePageContent(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Content edited successfully!");
        }
        navigate("/content");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProposalData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          Edit Landing Page Content
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Order Number:</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="new_form_control"
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((optionValue) => (
                    <option key={optionValue} value={optionValue.toString()}>
                      {optionValue}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  name="title"
                  defaultValue={proposalData?.title}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>

                <Form.Control
                  type="file"
                  name="image"
                  onChange={(e) => handleImageChange(e)}
                />
                {proposalData ? (
                  <>
                    {image.preview === "" ? (
                      <img
                        src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
                        alt="Categroy Icon"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "20px",
                          marginLeft: "20px",
                        }}
                      />
                    ) : (
                      <img
                        src={image.preview}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "20px",
                          marginLeft: "20px",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <span>No Image Available</span>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description:</Form.Label>

                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleEditorChange}
                  data={proposalData?.description}
                  config={{
                    height: "1000px",
                  }}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Redirection URL:</Form.Label>
                <Form.Control
                  name="redirection_url"
                  defaultValue={proposalData?.redirection_url}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <div className="button">
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                onClick={handleSubmit}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Save
              </Button>
              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToContent();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default EditContent;
