import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { GetProposalById } from "../../services/Api/Api";
import { BASE_URL_IMAGE, BASE_URL_DOCS } from "../../services/Host";
import { Card } from "primereact/card";

const ViewProposal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [proposalData, setProposalData] = useState([]);
  const [loading, setLoading] = useState(true);


  const navigateToProposal = () => {
    navigate("/proposals");
  };

  //get role By ID
  useLayoutEffect(() => {
    setLoading(true); // Set loading to true when starting to fetch the document
    GetProposalById(id)
      .then((res) => {
        setProposalData(res.data.data);
        setLoading(false); // Set loading to false when document is available
        console.log("data=>", res.data.data);
      })
      .catch((err) => {
        setLoading(false); // Set loading to false in case of an error
        console.log(err, "error");
      });
  }, [id]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          View Proposal
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Title Of Proposal</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.title}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Proposal Type</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={proposalData?.proposal_type}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Categories</Form.Label>
                <Form.Control
                  name="type"
                  type="text"
                  // defaultValue={blogData?.Categories?.title}
                  defaultValue={proposalData?.Categories?.map(
                    (category) => category.title
                  ).join(", ")}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  {proposalData && proposalData.proposal_type === "DOWNLOADABLE" ? (
                    <Form.Group className="mb-3">
                      <Form.Label>Document:</Form.Label>
                      <br />
                      {proposalData ? (
                        <iframe
                          title="document"
                          src={`https://docs.google.com/viewer?url=https://node.automatedpricingtool.io:5000/Docs/${proposalData?.file_name}&embedded=true`}
                          width="500"
                          height="300"
                          style={{ marginTop: "20px", marginLeft: "20px" }}
                        ></iframe>
                      ) : (
                        <span>No document Available</span>
                      )}
                      <br />
                    </Form.Group>
                  ) : (
                    proposalData && proposalData.proposal_type === "EDITABLE" ? (
                      // Your existing code for other file types
                      <>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Image:</Form.Label>
                          <br />
                          {proposalData ? (
                            <img
                              src={`${BASE_URL_IMAGE}${proposalData?.file_name}`}
                              alt="proposal Icon"
                              className="category-icon-preview"
                              style={{ height: "100px", width: "100px" }}
                            />
                          ) : (
                            <span>No Image Available</span>
                          )}
                          <br />
                        </Form.Group> */}
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Description</Form.Label>
                          <div
                            style={{
                              backgroundColor: "#EBECF2",
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da ",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: proposalData?.description,
                            }}
                          />
                        </Form.Group> */}
                      </>
                    ) : (
                      <span>No File Available</span>
                    )
                  )}
                </>
              )}
            </Row>

            <div>
              <Button
                // icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToProposal();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Return to Proposals
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default ViewProposal;
