import { Box } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  GetBannerContentById,
  UpdateBannerContent,
} from "../../../services/Api/Api";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

const EditBannerContent = () => {
  const { id } = useParams();
  const [idData, setIdData] = React.useState({});

  //get role By ID
  useLayoutEffect(() => {
    GetBannerContentById(id)
      .then((res) => {
        setIdData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setIdData((prevData) => ({
      ...prevData,
      banner_content: data,
    }));
  };
  //update role api implementation
  const onChange = (e) => {
    setIdData({ ...idData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content_id", id);
    formData.append(
      "banner_content",
      idData?.banner_content ? idData?.banner_content : ""
    );
    formData.append(
      "heading",
      idData?.heading ? idData?.heading : ""
    );

    UpdateBannerContent(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Social Media edited successfully!");
        }
        navigate("/bannerContent");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const navigate = useNavigate();
  const navigateToSocialList = () => {
    navigate("/bannerContent");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginBottom: "60px" }}>Edit Banner Content</h3>
      </Box>
      <Card>
        <div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Banner Heading</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.heading}
                name="heading"
                onChange={(e) => onChange(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Description:</Form.Label>

                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleEditorChange}
                  data={idData?.banner_content}
                  config={{
                    height: "1000px",
                  }}
                />
              </Form.Group>

         
          </Form>
          <div className="button">
            <Button
              icon="pi pi-check"
              severity="success"
              type="submit"
              onClick={handleSubmit}
              style={{
                borderRadius: "10px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Save
            </Button>

            <Button
              icon="pi pi-times"
              severity="secondary"
              onClick={(e) => {
                navigateToSocialList();
              }}
              style={{ borderRadius: "10px", marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Box>
  );
};

export default EditBannerContent;
