import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { GetHomePageContentById } from "../../services/Api/Api";
import { BASE_URL_IMAGE } from "../../services/Host";
import { Card } from "primereact/card";

const ViewContent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contentData, setContentData] = useState([]);

  const navigateToContent = () => {
    navigate("/content");
  };

  //get role By ID
  useLayoutEffect(() => {
    GetHomePageContentById(id)
      .then((res) => {
        setContentData(res.data.data);
        console.log("content", res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          View Landing Page Content
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
          <Row>
              <Form.Group className="mb-3">
                <Form.Label>Order Number Of this Content on website :</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={contentData?.order_number}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Title Of Landing Page Content:</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={contentData?.title}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>
                <br />
                {contentData?.file_name ? (
                  <img
                    src={`${BASE_URL_IMAGE}${contentData?.file_name}`}
                    alt="Content Icon"
                    className="category-icon-preview"
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <span>No Image Available</span>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={contentData?.description}
                  as="textarea"
                  disabled
                  rows={6}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Redirection Url:</Form.Label>
                <Form.Control
                  name="redirection_url"
                  defaultValue={contentData?.redirection_url}
                  type="text"
                  rows={9}
                  disabled
                />
              </Form.Group>
            </Row>

            <div>
              <Button
                // icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToContent();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Return to Content
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default ViewContent;
