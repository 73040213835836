import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { GetCalculatorById, UpdateCalculator } from "../../../services/Api/Api";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BASE_URL_IMAGE } from "../../../services/Host";

const EditCalculator = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [proposalData, setProposalData] = useState([]);
  const [checkImage, setCheckImage] = React.useState(null);
  const [image, setImage] = useState({ preview: "", raw: "" });

  const navigateToProposal = () => {
    navigate("/calculator");
  };

  //get proposal By ID
  useLayoutEffect(() => {
    GetCalculatorById(id)
      .then((res) => {
        setProposalData(res.data.data);
        setCheckImage(res.data.data.file_name);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
    setProposalData({ ...proposalData, image: e.target.files[0] });
  };

  const onChange = (e) => {
    setProposalData({ ...proposalData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("calculator_id", id);
    formData.append(
      "calculator_name",
      proposalData?.calculator_name ? proposalData?.calculator_name : ""
    );
    formData.append(
      "calculator_use",
      proposalData?.calculator_use ? proposalData?.calculator_use : ""
    );
    formData.append(
      "profit_margin_formula",
      proposalData?.profit_margin_formula
        ? proposalData?.profit_margin_formula
        : ""
    );
    formData.append(
      "description",
      proposalData?.description ? proposalData?.description : ""
    );
    if (proposalData.image) {
      formData.append("images", proposalData.image);
    }

    UpdateCalculator(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Calculator edited successfully!");
        }
        navigate("/calculator");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProposalData((prevData) => ({
      ...prevData,
      profit_margin_formula: data,
    }));
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginTop: "5px", marginBottom: "30px" }}>
          Edit Calculator
        </h3>
      </Box>

      <Card>
        <div className="admin_profile">
          <Form className="admin_details_form">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Name:</Form.Label>
                <Form.Control
                  name="calculator_name"
                  defaultValue={proposalData?.calculator_name}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>

                <Form.Control
                  type="file"
                  name="image"
                  onChange={(e) => handleImageChange(e)}
                />
                {proposalData ? (
                  <>
                    {image.preview === "" ? (
                      <img
                        src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
                        alt="Proposal Banner "
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "20px",
                          marginLeft: "20px",
                        }}
                      />
                    ) : (
                      <img
                        src={image.preview}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "20px",
                          marginLeft: "20px",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <span>No Image Available</span>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Description:</Form.Label>

                <Form.Control
                  name="description"
                  defaultValue={proposalData?.description}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            {/* <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Use:</Form.Label>
                <Form.Control
                  name="calculator_use"
                  defaultValue={proposalData?.calculator_use}
                  type="text"
                  rows={9}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row> */}

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Calculator Use, Content & Formula:</Form.Label>

                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleEditorChange}
                  data={proposalData?.profit_margin_formula}
                  config={{
                    height: "1000px",
                  }}
                />
              </Form.Group>
            </Row>

            <div className="button">
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                onClick={handleSubmit}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Save
              </Button>
              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToProposal();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Box>
  );
};

export default EditCalculator;
