import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Form from "react-bootstrap/Form";
import { CreateHomePageContent } from "../../services/Api/Api";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import imageCompression from "browser-image-compression";

const AddContent = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [images, setImages] = useState(null);
  const [orderNumber, setOrderNumber] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [disable, setDisable] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]; // Add more allowed types if needed

      if (!allowedTypes.includes(file.type)) {
        console.error("Error: Invalid file type. Images (JPEG, JPG,PNG) only!");
        return;
      }

      if (file.size <= 1024 * 1024) {
        // If image size is less than or equal to 1 MB, no need to compress
        setImages(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Compress the image to 25% if its size is more than 1 MB
        try {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
          });

          // Check if compression actually reduced the size
          if (compressedFile.size < file.size) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
              setImages(new Blob([compressedFile], { type: "image/jpeg" }));
            };
            reader.readAsDataURL(compressedFile);
          } else {
            // If compression did not reduce the size, use the original image
            setImages(file);
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
          }
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault();
    setImages(null);
    setImagePreview(null);

    // Clear the file input value
    const fileInput = document.getElementById("imageInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!title) {
      setDisable(false);
      toast.error("Please enter heading");
      return;
    }
    if (!description) {
      setDisable(false);
      toast.error("Please enter description");
      return;
    }
    if (!url) {
      setDisable(false);
      toast.error("Please enter Url");
      return;
    }
    if (!orderNumber) {
      setDisable(false);
      toast.error("Please enter Order Number");
      return;
    }
    if (!images) {
      setDisable(false);
      toast.error("Please insert image");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("redirection_url", url);
      formData.append("order_number", orderNumber);
      if (images) {
        formData.append("images", images);
      }

      const response = await CreateHomePageContent(formData);
      console.log("response===>", response);

      if (response.status === 200) {
        toast.success("Content added successfully");
        setTimeout(() => {
          // Refresh the page
          window.location.reload();
        }, 1000);
      }
      setDisable(false);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Content with this title already exist", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error.response.status === 401) {
        toast.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const navigateToContent = () => {
    navigate("/content");
  };

  return (
    <div>
      <h3 style={{ marginBottom: "30px" }}>
        Create a New Landing Page Content
      </h3>

      <Card>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Type:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="new_form_control"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              >
                <option>Select Order Number:</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label> Title:</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="new_form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Image:</Form.Label>
              <Form.Control
                type="file"
                required
                accept="images/*"
                id="imageInput"
                onChange={handleImageChange}
              />
            </Form.Group>
            {imagePreview && (
              <div
                style={{ position: "relative" }}
                onClick={(e) => {
                  handleRemoveImage(e);
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "10px",
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  color="warning"
                  style={{
                    position: "absolute",
                    left: "99px",
                  }}
                />
              </div>
            )}

            <Form.Group className="mb-3">
              <Form.Label> Description:</Form.Label>

              <CKEditor
                editor={ClassicEditor}
                onChange={handleEditorChange}
                config={{
                  height: "1000px",
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label> Redirection URL:</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Redirection URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="new_form_control"
              />
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label>Redirection URL:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="new_form_control"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              >
                <option>Select URL</option>
                <option value="SUBSCRIBED">PAID</option>
                <option value="UNSUBSCRIBED">UNPAID</option>
              </Form.Select>
            </Form.Group> */}

            <div>
              <Button
                icon="pi pi-check"
                severity="success"
                type="submit"
                onClick={handleSubmit}
                disabled={disable}
                style={{
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                  // width:"10px"
                }}
              >
                {disable ? "Saving...." : "Save"}
              </Button>

              <Button
                icon="pi pi-times"
                severity="secondary"
                onClick={(e) => {
                  navigateToContent();
                }}
                style={{ borderRadius: "10px", marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddContent;
