import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import AdminList from "./pages/AdminList";
import RoleList from "./pages/Roles/RoleList";
import AddRole from "./pages/Roles/AddRole";
import EditRole from "./pages/Roles/EditRole";
import Customers from "./pages/Customer/Customers";
import ViewCustomer from "./pages/Customer/ViewCustomer";
import AddCustomer from "./pages/Customer/AddCustomer";
import AdminUser from "./pages/AdminManagement/AdminUser";
import ViewAdmin from "./pages/AdminManagement/ViewAdmin";
import AddAdmin from "./pages/AdminManagement/AddAdmin";
import EditAdmin from "./pages/AdminManagement/EditAdmin";
import Categorylist from "./pages/Category/CategoryList";
import AddCategory from "./pages/Category/AddCategory";
import EditCategory from "./pages/Category/EditCategory";
import ProposalList from "./pages/Proposal/ProposalList";
import AddProposal from "./pages/Proposal/AddProposal";
import ViewProposal from "./pages/Proposal/ViewProposal";
import EditProposal from "./pages/Proposal/EditProposal";
import Content from "./pages/HomePageContent/Content";
import AddContent from "./pages/HomePageContent/AddContent";
import EditContent from "./pages/HomePageContent/EditContent";
import ViewContent from "./pages/HomePageContent/ViewContent";
import Calculator from "./pages/Calculator/Calculator/Calculator";
import AddCalculator from "./pages/Calculator/Calculator/AddCalculator";
import EditCalculator from "./pages/Calculator/Calculator/EditCalculator";
import ViewCalculator from "./pages/Calculator/Calculator/ViewCalculator";
import AdvancedPriceExhibit from "./pages/Calculator/AdvancedPriceExhibit/AdvancedPriceExhibit";
import LoginLogs from "./pages/LoginLogs";
import BannerContent from "./pages/HomePageContent/BannerContent/BannerContent";
import EditBannerContent from "./pages/HomePageContent/BannerContent/EditBannerContent";
import AddBannerContent from "./pages/HomePageContent/BannerContent/AddBannerContent";
import SocialLogins from "./pages/HomePageContent/SocialLogins/SocialLogins";
import EditSocialLogin from "./pages/HomePageContent/SocialLogins/EditSocialLogin";
import AddSocialLogin from "./pages/HomePageContent/SocialLogins/AddSocialLogin"




function App() {
 
  return (
    <Router>
      <Routes>
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/reset-password" element={<ChangePassword />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/viewCustomer/:id" element={<ViewCustomer />} />
          <Route path="/addCustomer" element={<AddCustomer />} />
          <Route path="adminUser" element={<AdminList />} />
          <Route path="/role-list" element={<RoleList />} />
          <Route path="/addRole" element={<AddRole />} />
          <Route path="/editRole/:id" element={<EditRole />} />
          <Route path="/adminList" element={<AdminUser />} />
          <Route path="/viewAdmin" element={<ViewAdmin />} />
          <Route path="/addAdmin" element={<AddAdmin />} />
          <Route path="/editAdmin/:id" element={<EditAdmin />} />
          <Route path="/category" element={<Categorylist />} />
          <Route path="/addCategory" element={<AddCategory />} />
          <Route path="/editCategory/:id" element={<EditCategory />} />
          <Route path="/proposals" element={<ProposalList />} />
          <Route path="/addProposal" element={<AddProposal />} />
          <Route path="/viewProposal/:id" element={<ViewProposal />} />
          <Route path="/editProposal/:id" element={<EditProposal />} />
          <Route path="/content" element={<Content />} />
          <Route path="/addContent" element={<AddContent />} />
          <Route path="/viewContent/:id" element={<ViewContent />} />
          <Route path="/editContent/:id" element={<EditContent />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/addCalculator" element={<AddCalculator />} />
          <Route path="/editCalculator/:id" element={<EditCalculator />} />
          <Route path="/viewCalculator/:id" element={<ViewCalculator />} />
          <Route path="/advancedPriceExhibit" element={<AdvancedPriceExhibit />} />
          <Route path="/loginLogs" element={<LoginLogs />} />
          <Route path="/socialLogins" element={<SocialLogins />} />
          <Route path="/addSocialLogin" element={<AddSocialLogin />} />
          <Route path="/editSocialLogin/:id" element={<EditSocialLogin />} />
          <Route path="/bannerContent" element={<BannerContent />} />
          <Route path="/addBannerContent" element={<AddBannerContent />} />
          <Route path="/editBannerContent/:id" element={<EditBannerContent />} />

        </Route>
        {/* <Route path = "/customers" element={<MainLayout> <Customers/></MainLayout>}/> */}
      </Routes>
    </Router>
  );
}

export default App;
