import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { AiTwotoneLike } from "react-icons/ai";
import { FcReading } from "react-icons/fc";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FcCalculator } from "react-icons/fc";
import { FcDocument } from "react-icons/fc";
import {
  GetUserCountByMonth,
  GetUserCount,
  GetProposalCount,
  GetMostViewdProposal,
  GetMostViewedCalculator,
} from "../services/Api/Api";

const getAllMonths = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months.map((month) => ({ type: month, sales: 0 }));
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [mostViewedCalculator, setMostViewedCalculator] = useState([]);
  const [mostViewedProposal, setMostViewedProposal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  //get views count
  const getMostViewedCalculatorData = async () => {
    try {
      const result = await GetMostViewedCalculator(
        localStorage.getItem("adminToken")
      );
      setMostViewedCalculator(result.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getMostViewedCalculatorData();
  }, []);

  //get views count
  const getMostViewedProposalData = async () => {
    try {
      const result = await GetMostViewdProposal(
        localStorage.getItem("adminToken")
      );
      setMostViewedProposal(result.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getMostViewedProposalData();
  }, []);

  //get customer count
  const getCustomerData = async () => {
    try {
      setLoading(true);
      const result = await GetUserCount(localStorage.getItem("adminToken"));
      setCustomerData(result.data.data);
      console.log("blogdata", result);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        setErrorMessage("You do not have access to this page as a sub-admin.");
      } else {
        setErrorMessage("Error loading data. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCustomerData();
  }, []);

  //get categroy count
  const getCategoryData = async () => {
    try {
      const result = await GetProposalCount(localStorage.getItem("adminToken"));
      setCategoryData(result.data.data);
      console.log("blogdata", result);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCategoryData();
  }, []);

  //get usercount by month api
  const getData = async () => {
    try {
      const result = await GetUserCountByMonth(
        localStorage.getItem("adminToken")
      );
      setUserData(result.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const formattedData =
    userData.length > 0
      ? userData.map(({ month, userCount }) => ({
          type: month,
          sales: userCount,
        }))
      : getAllMonths();

  const config = {
    data: formattedData,
    xField: "type",
    yField: "sales",
    color: ({ type }) => "#8f001e",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "User Count",
      },
    },
  };

  const navigateToViewLoginLogs = () => {
    navigate("/loginLogs");
  };

  return (
    <div>
      {loading && <p style={{ fontSize: "16px" }}>Loading...</p>}
      {errorMessage && (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
          {errorMessage}
        </p>
      )}
      {!loading && !errorMessage && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20PX",
            }}
          >
            <h3 className="title">Dashboard</h3>

            <Button
              label="Click To View Login Logs"
              icon="pi pi-arrow-up-right"
              iconPos="right"
              rounded
              outlined
              aria-label="Filter"
              onClick={navigateToViewLoginLogs}
              style={{
                borderRadius: "10px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center gap-3 mb-3 ">
            <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
              <div>
                <p className="desc">Most Used Calculator</p>
                <h4 className="mb-0 sub-title">
                  {mostViewedCalculator
                    ? mostViewedCalculator.views_count
                    : "N/A"}
                </h4>
              </div>
              <div className="d-flex flex-column align-items-end">
                <h6 className="red">
                  <FcCalculator style={{ fontSize: "30px" }} />
                </h6>
                <p className="mb-0  desc">
                  {mostViewedCalculator
                    ? mostViewedCalculator.calculator_name
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
              <div>
                <p className="desc">Most Used Proposals</p>
                <h4 className="mb-0 sub-title">
                  {mostViewedProposal ? mostViewedProposal.views_count : "N/A"}
                </h4>
              </div>
              <div className="d-flex flex-column align-items-end">
                <h6 className="red">
                  <FcDocument style={{ fontSize: "30px" }} />
                </h6>
                <p className="mb-0  desc">
                  {mostViewedProposal ? mostViewedProposal.title : "N/A"}
                </p>
              </div>
            </div>
            <div></div>
          </div>

          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
              <div>
                <p className="desc">Total Users</p>
                <h4 className="mb-0 sub-title">{customerData}</h4>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
              <div>
                <p className="desc">Total Proposals</p>
                <h4 className="mb-0 sub-title">{categoryData}</h4>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="mb-5 title">
              User Registration Statistics by Month
            </h3>
            <div>
              <Column {...config} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
